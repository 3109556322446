
/**
 * Load external scripts by src. Makes sure that scripts
 * are only loaded once.
 */
class ScriptCache {

  loaded = new Set();
  failed = new Set();
  pending = new Map();

  /**
   * Load script by src.
   */
  loadSrc(src, callbackName) {
    if (this.loaded.has(src)) {
      return Promise.resolve(src);
    }

    if (this.pending.has(src)) {
      return this.pending.get(src);
    }

    const loadScriptPromise = this.loadScript(src, callbackName);
    this.pending.set(src, loadScriptPromise);

    return loadScriptPromise;
  }

  loadScript(src, callbackName) {
    return new Promise(function (resolve, reject) {
      var scriptElement = document.createElement('script');
      scriptElement.async = true;
      scriptElement.src = src;

      const onLoad = function () {
        resolve(src);
      };

      if (callbackName) {
        window[callbackName] = onLoad;
      } else {
        scriptElement.onload = onLoad;
      }

      scriptElement.onerror = function () {
        reject(src);
      };

      document.body.appendChild(scriptElement);
    });
  }
}

export const AppScriptCache = new ScriptCache();
